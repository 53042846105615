import React from "react";
import { Link } from "gatsby";
import Intro from "~/intro";
import Layout from "~/layout";
import SEO from "~/seo";

const NotFoundPage = () => (
    <Layout fullHeight={true}>
        <Intro title="Nicht gefunden">
            Die gesuchte Seite konnte nicht gefunden.
            <br />
            <Link to="/" className="text-gray-400 hover:no-underline hover:text-dark">
                Zur Startseite
            </Link>
        </Intro>
    </Layout>
);

export const Head = () => <SEO title="Nicht gefunden" />;

export default NotFoundPage;
